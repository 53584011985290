
export const BEHAVIOUR = `.disabled {
  pointer-events: none;
  opacity: 0.8; }
`;
export const BUTTONS = `.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 700;
  transition: background-color 0.2s, border-color 0.2s;
  gap: 8px;
  color: var(--white);
  fill: var(--white);
  background: var(--purple);
  box-shadow: var(--shadow-purple);
  border: 2px solid var(--purple);
  text-decoration: none;
  box-sizing: border-box; }

.btn:hover {
  background: var(--dark-purple); }

.btn:focus-within,
.btn:focus,
.btn:active {
  background: var(--purple-80);
  box-shadow: 0 0 0 4px var(--purple-20); }

.btn-outline {
  color: var(--purple);
  fill: var(--purple);
  background: var(--white);
  border: 2px solid var(--purple-20); }

.btn-link {
  color: var(--purple);
  fill: var(--purple);
  font-size: var(--tiny);
  font-weight: 600 !important;
  background: none;
  border: none;
  box-shadow: none;
  padding: 0; }

.btn-link:hover {
  background: none;
  text-decoration: underline; }

.btn-circle {
  border-radius: 50%;
  padding: 8px;
  width: 48px;
  height: 48px; }

.btn-outline:hover,
.btn-outline.loader-block,
.btn-outline:focus-within,
.btn-outline:focus,
.btn-outline:active {
  background: #F9F6FE;
  border-color: #C1A7F2; }

.btn-outline:focus-within,
.btn-outline:focus,
.btn-outline:active {
  box-shadow: 0 0 0 4px var(--purple-20); }

.btn-white {
  color: var(--gray-80);
  fill: var(--gray-80);
  background: var(--white);
  border: 2px solid var(--white); }

.btn-white:hover {
  background: var(--gray-5); }

.btn-white:focus-within,
.btn-white:focus,
.btn-white:active {
  background: var(--gray-10);
  box-shadow: 0 0 0 4px var(--gray-20); }

.btn-red {
  color: var(--white);
  fill: var(--white);
  background: var(--red);
  border: 2px solid var(--red); }

.btn-red:hover {
  background: var(--red-dark); }

.btn-red:focus-within,
.btn-red:focus,
.btn-red:active {
  background: var(--red-80);
  box-shadow: 0 0 0 4px var(--red-20); }

.btn-red.btn-outline {
  color: var(--red);
  fill: var(--red);
  background: var(--white);
  border: 2px solid var(--red); }

.btn-red.btn-outline:hover {
  color: var(--red);
  fill: var(--red);
  background: var(--white);
  border: 2px solid var(--red-60); }

.btn-red.btn-outline:focus-within,
.btn-red.btn-outline:focus,
.btn-red.btn-outline:active {
  box-shadow: 0 0 0 4px var(--red-20); }

.btn-white.btn-outline {
  color: var(--gray-80); }

/**
 * Sizes
 */
.btn-lg {
  padding: 14px 24px; }
`;
export const CHIPS = `.chip-gray,
.chip-black,
.chip-purple,
.chip-red,
.chip-blue,
.chip-green,
.chip-purple,
.chip-yellow {
  border-radius: 8px;
  font-size: 12px;
  font-weight: 400;
  padding: 4px; }

/* TODO: Separate into each color base on needs */
.chip-gray,
.chip-black,
.chip-red,
.chip-green,
.chip-yellow {
  background-color: var(--gray-5);
  color: var(--gray-80); }

.chip-blue {
  background-color: var(--blue-10);
  color: var(--blue-120); }

.chip-gray {
  border: 1px solid var(--gray-20, #DDE1E9);
  background: var(--gray-5, #F8F9FB); }

.chip-purple {
  border: 1px solid var(--purple-50, #C1A7F2);
  background: var(--purple-5, #F9F6FE);
  color: var(--purple-110, #693FCB);
  box-shadow: 0px 4px 16px 0px rgba(131, 80, 230, 0.2); }

.chip-square {
  display: flex;
  padding: 9.5px 21px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; }
`;
export const COLORS = `.text-purple {
  color: var(--purple) !important;
  fill: var(--purple) !important; }

.bg-purple {
  background-color: var(--purple) !important; }

.text-purple-5 {
  color: var(--purple-5) !important;
  fill: var(--purple-5) !important; }

.bg-purple-5 {
  background-color: var(--purple-5) !important; }

.text-purple-10 {
  color: var(--purple-10) !important;
  fill: var(--purple-10) !important; }

.bg-purple-10 {
  background-color: var(--purple-10) !important; }

.text-purple-20 {
  color: var(--purple-20) !important;
  fill: var(--purple-20) !important; }

.bg-purple-20 {
  background-color: var(--purple-20) !important; }

.text-purple-40 {
  color: var(--purple-40) !important;
  fill: var(--purple-40) !important; }

.bg-purple-40 {
  background-color: var(--purple-40) !important; }

.text-purple-50 {
  color: var(--purple-50) !important;
  fill: var(--purple-50) !important; }

.bg-purple-50 {
  background-color: var(--purple-50) !important; }

.text-purple-60 {
  color: var(--purple-60) !important;
  fill: var(--purple-60) !important; }

.bg-purple-60 {
  background-color: var(--purple-60) !important; }

.text-purple-80 {
  color: var(--purple-80) !important;
  fill: var(--purple-80) !important; }

.bg-purple-80 {
  background-color: var(--purple-80) !important; }

.text-purple-110 {
  color: var(--purple-110) !important;
  fill: var(--purple-110) !important; }

.bg-purple-110 {
  background-color: var(--purple-110) !important; }

.text-shadow-purple {
  color: var(--shadow-purple) !important;
  fill: var(--shadow-purple) !important; }

.bg-shadow-purple {
  background-color: var(--shadow-purple) !important; }

.text-white {
  color: var(--white) !important;
  fill: var(--white) !important; }

.bg-white {
  background-color: var(--white) !important; }

.text-white-5 {
  color: var(--white-5) !important;
  fill: var(--white-5) !important; }

.bg-white-5 {
  background-color: var(--white-5) !important; }

.text-white-20 {
  color: var(--white-20) !important;
  fill: var(--white-20) !important; }

.bg-white-20 {
  background-color: var(--white-20) !important; }

.text-white-60 {
  color: var(--white-60) !important;
  fill: var(--white-60) !important; }

.bg-white-60 {
  background-color: var(--white-60) !important; }

.text-white-80 {
  color: var(--white-80) !important;
  fill: var(--white-80) !important; }

.bg-white-80 {
  background-color: var(--white-80) !important; }

.text-gray-5 {
  color: var(--gray-5) !important;
  fill: var(--gray-5) !important; }

.bg-gray-5 {
  background-color: var(--gray-5) !important; }

.text-gray-10 {
  color: var(--gray-10) !important;
  fill: var(--gray-10) !important; }

.bg-gray-10 {
  background-color: var(--gray-10) !important; }

.text-gray-20 {
  color: var(--gray-20) !important;
  fill: var(--gray-20) !important; }

.bg-gray-20 {
  background-color: var(--gray-20) !important; }

.text-gray-40 {
  color: var(--gray-40) !important;
  fill: var(--gray-40) !important; }

.bg-gray-40 {
  background-color: var(--gray-40) !important; }

.text-gray-50 {
  color: var(--gray-50) !important;
  fill: var(--gray-50) !important; }

.bg-gray-50 {
  background-color: var(--gray-50) !important; }

.text-gray-60 {
  color: var(--gray-60) !important;
  fill: var(--gray-60) !important; }

.bg-gray-60 {
  background-color: var(--gray-60) !important; }

.text-gray-80 {
  color: var(--gray-80) !important;
  fill: var(--gray-80) !important; }

.bg-gray-80 {
  background-color: var(--gray-80) !important; }

.text-gray-100 {
  color: var(--gray-100) !important;
  fill: var(--gray-100) !important; }

.bg-gray-100 {
  background-color: var(--gray-100) !important; }

.text-black-full {
  color: var(--black-full) !important;
  fill: var(--black-full) !important; }

.bg-black-full {
  background-color: var(--black-full) !important; }

.text-black {
  color: var(--black) !important;
  fill: var(--black) !important; }

.bg-black {
  background-color: var(--black) !important; }

.text-black-10 {
  color: var(--black-10) !important;
  fill: var(--black-10) !important; }

.bg-black-10 {
  background-color: var(--black-10) !important; }

.text-black-20 {
  color: var(--black-20) !important;
  fill: var(--black-20) !important; }

.bg-black-20 {
  background-color: var(--black-20) !important; }

.text-black-40 {
  color: var(--black-40) !important;
  fill: var(--black-40) !important; }

.bg-black-40 {
  background-color: var(--black-40) !important; }

.text-black-50 {
  color: var(--black-50) !important;
  fill: var(--black-50) !important; }

.bg-black-50 {
  background-color: var(--black-50) !important; }

.text-black-60 {
  color: var(--black-60) !important;
  fill: var(--black-60) !important; }

.bg-black-60 {
  background-color: var(--black-60) !important; }

.text-black-80 {
  color: var(--black-80) !important;
  fill: var(--black-80) !important; }

.bg-black-80 {
  background-color: var(--black-80) !important; }

.text-green-dark {
  color: var(--green-dark) !important;
  fill: var(--green-dark) !important; }

.bg-green-dark {
  background-color: var(--green-dark) !important; }

.text-green {
  color: var(--green) !important;
  fill: var(--green) !important; }

.bg-green {
  background-color: var(--green) !important; }

.text-green-20 {
  color: var(--green-20) !important;
  fill: var(--green-20) !important; }

.bg-green-20 {
  background-color: var(--green-20) !important; }

.text-green-40 {
  color: var(--green-40) !important;
  fill: var(--green-40) !important; }

.bg-green-40 {
  background-color: var(--green-40) !important; }

.text-green-50 {
  color: var(--green-50) !important;
  fill: var(--green-50) !important; }

.bg-green-50 {
  background-color: var(--green-50) !important; }

.text-green-60 {
  color: var(--green-60) !important;
  fill: var(--green-60) !important; }

.bg-green-60 {
  background-color: var(--green-60) !important; }

.text-green-80 {
  color: var(--green-80) !important;
  fill: var(--green-80) !important; }

.bg-green-80 {
  background-color: var(--green-80) !important; }

.text-blue {
  color: var(--blue) !important;
  fill: var(--blue) !important; }

.bg-blue {
  background-color: var(--blue) !important; }

.text-blue-5 {
  color: var(--blue-5) !important;
  fill: var(--blue-5) !important; }

.bg-blue-5 {
  background-color: var(--blue-5) !important; }

.text-blue-10 {
  color: var(--blue-10) !important;
  fill: var(--blue-10) !important; }

.bg-blue-10 {
  background-color: var(--blue-10) !important; }

.text-blue-20 {
  color: var(--blue-20) !important;
  fill: var(--blue-20) !important; }

.bg-blue-20 {
  background-color: var(--blue-20) !important; }

.text-blue-40 {
  color: var(--blue-40) !important;
  fill: var(--blue-40) !important; }

.bg-blue-40 {
  background-color: var(--blue-40) !important; }

.text-blue-50 {
  color: var(--blue-50) !important;
  fill: var(--blue-50) !important; }

.bg-blue-50 {
  background-color: var(--blue-50) !important; }

.text-blue-60 {
  color: var(--blue-60) !important;
  fill: var(--blue-60) !important; }

.bg-blue-60 {
  background-color: var(--blue-60) !important; }

.text-blue-80 {
  color: var(--blue-80) !important;
  fill: var(--blue-80) !important; }

.bg-blue-80 {
  background-color: var(--blue-80) !important; }

.text-blue-120 {
  color: var(--blue-120) !important;
  fill: var(--blue-120) !important; }

.bg-blue-120 {
  background-color: var(--blue-120) !important; }

.text-blue-dark {
  color: var(--blue-dark) !important;
  fill: var(--blue-dark) !important; }

.bg-blue-dark {
  background-color: var(--blue-dark) !important; }

.text-yellow {
  color: var(--yellow) !important;
  fill: var(--yellow) !important; }

.bg-yellow {
  background-color: var(--yellow) !important; }

.text-yellow-5 {
  color: var(--yellow-5) !important;
  fill: var(--yellow-5) !important; }

.bg-yellow-5 {
  background-color: var(--yellow-5) !important; }

.text-yellow-10 {
  color: var(--yellow-10) !important;
  fill: var(--yellow-10) !important; }

.bg-yellow-10 {
  background-color: var(--yellow-10) !important; }

.text-yellow-20 {
  color: var(--yellow-20) !important;
  fill: var(--yellow-20) !important; }

.bg-yellow-20 {
  background-color: var(--yellow-20) !important; }

.text-yellow-40 {
  color: var(--yellow-40) !important;
  fill: var(--yellow-40) !important; }

.bg-yellow-40 {
  background-color: var(--yellow-40) !important; }

.text-yellow-50 {
  color: var(--yellow-50) !important;
  fill: var(--yellow-50) !important; }

.bg-yellow-50 {
  background-color: var(--yellow-50) !important; }

.text-yellow-60 {
  color: var(--yellow-60) !important;
  fill: var(--yellow-60) !important; }

.bg-yellow-60 {
  background-color: var(--yellow-60) !important; }

.text-yellow-80 {
  color: var(--yellow-80) !important;
  fill: var(--yellow-80) !important; }

.bg-yellow-80 {
  background-color: var(--yellow-80) !important; }

.text-yellow-110 {
  color: var(--yellow-110) !important;
  fill: var(--yellow-110) !important; }

.bg-yellow-110 {
  background-color: var(--yellow-110) !important; }

.text-yellow-120 {
  color: var(--yellow-120) !important;
  fill: var(--yellow-120) !important; }

.bg-yellow-120 {
  background-color: var(--yellow-120) !important; }

.text-yellow-130 {
  color: var(--yellow-130) !important;
  fill: var(--yellow-130) !important; }

.bg-yellow-130 {
  background-color: var(--yellow-130) !important; }

.text-red-dark {
  color: var(--red-dark) !important;
  fill: var(--red-dark) !important; }

.bg-red-dark {
  background-color: var(--red-dark) !important; }

.text-red {
  color: var(--red) !important;
  fill: var(--red) !important; }

.bg-red {
  background-color: var(--red) !important; }

.text-red-80 {
  color: var(--red-80) !important;
  fill: var(--red-80) !important; }

.bg-red-80 {
  background-color: var(--red-80) !important; }

.text-red-60 {
  color: var(--red-60) !important;
  fill: var(--red-60) !important; }

.bg-red-60 {
  background-color: var(--red-60) !important; }

.text-red-50 {
  color: var(--red-50) !important;
  fill: var(--red-50) !important; }

.bg-red-50 {
  background-color: var(--red-50) !important; }

.text-red-40 {
  color: var(--red-40) !important;
  fill: var(--red-40) !important; }

.bg-red-40 {
  background-color: var(--red-40) !important; }

.text-red-20 {
  color: var(--red-20) !important;
  fill: var(--red-20) !important; }

.bg-red-20 {
  background-color: var(--red-20) !important; }

.text-red-10 {
  color: var(--red-10) !important;
  fill: var(--red-10) !important; }

.bg-red-10 {
  background-color: var(--red-10) !important; }

.text-red-5 {
  color: var(--red-5) !important;
  fill: var(--red-5) !important; }

.bg-red-5 {
  background-color: var(--red-5) !important; }

.text-orange-130 {
  color: var(--orange-130) !important;
  fill: var(--orange-130) !important; }

.bg-orange-130 {
  background-color: var(--orange-130) !important; }

.text-orange-120 {
  color: var(--orange-120) !important;
  fill: var(--orange-120) !important; }

.bg-orange-120 {
  background-color: var(--orange-120) !important; }

.text-orange-110 {
  color: var(--orange-110) !important;
  fill: var(--orange-110) !important; }

.bg-orange-110 {
  background-color: var(--orange-110) !important; }

.text-orange {
  color: var(--orange) !important;
  fill: var(--orange) !important; }

.bg-orange {
  background-color: var(--orange) !important; }

.text-orange-80 {
  color: var(--orange-80) !important;
  fill: var(--orange-80) !important; }

.bg-orange-80 {
  background-color: var(--orange-80) !important; }

.text-orange-60 {
  color: var(--orange-60) !important;
  fill: var(--orange-60) !important; }

.bg-orange-60 {
  background-color: var(--orange-60) !important; }

.text-orange-50 {
  color: var(--orange-50) !important;
  fill: var(--orange-50) !important; }

.bg-orange-50 {
  background-color: var(--orange-50) !important; }

.text-orange-40 {
  color: var(--orange-40) !important;
  fill: var(--orange-40) !important; }

.bg-orange-40 {
  background-color: var(--orange-40) !important; }

.text-orange-20 {
  color: var(--orange-20) !important;
  fill: var(--orange-20) !important; }

.bg-orange-20 {
  background-color: var(--orange-20) !important; }

.text-orange-10 {
  color: var(--orange-10) !important;
  fill: var(--orange-10) !important; }

.bg-orange-10 {
  background-color: var(--orange-10) !important; }

.text-orange-5 {
  color: var(--orange-5) !important;
  fill: var(--orange-5) !important; }

.bg-orange-5 {
  background-color: var(--orange-5) !important; }
`;
export const FORMS = `/* resets */
input,
textarea {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  border: none;
  outline: none;
  background: none;
  padding: 0;
  margin: 0; }

textarea {
  height: 52px;
  min-height: 100px; }

.field {
  position: relative;
  width: 100%;
  font-family: unset;
  box-sizing: border-box; }
  .field * {
    box-sizing: border-box; }
  .field.icon-left, .field.icon-right {
    transition: all 0.1s;
    line-height: initial; }
  .field.icon-right-error .field-icon-right {
    top: 28px; }
  .field.icon-right .field-control,
  .field.icon-right .field-control-without-label {
    padding-right: 10px; }
  .field.icon-left .field-control,
  .field.icon-left .field-control-without-label {
    padding-left: 16px; }
  .field.icon-left > label {
    padding-left: 11px; }
  .field-icon-left, .field-icon-right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    cursor: pointer;
    display: flex;
    align-items: center; }
  .field-icon-left {
    left: 12px; }
  .field-icon-right {
    right: 12px; }
  .field-control-without-label, .field-control-readonly, .field-control {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-clip: padding-box;
    background-color: var(--white);
    border-radius: 8px;
    border: 1px solid var(--gray-20);
    box-shadow: none;
    color: var(--gray-80);
    cursor: text;
    display: block;
    font-size: var(--font-size-default-mb);
    font-weight: 400;
    font-family: OpenSans, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    line-height: 150%;
    max-height: 126px;
    min-height: 52px;
    overflow: auto;
    padding: 8px 12px;
    resize: none;
    transition: all 0.1s;
    width: 100%;
    outline: none;
    box-sizing: border-box; }
    .field-control-without-label::placeholder, .field-control-readonly::placeholder, .field-control::placeholder {
      color: transparent; }
    .field-control-without-label:hover, .field-control-readonly:hover, .field-control:hover {
      border-color: var(--purple) !important; }
    .field-control-without-label.focus, .field-control-without-label:focus, .field-control-readonly.focus, .field-control-readonly:focus, .field-control.focus, .field-control:focus {
      border: 1px solid var(--purple);
      box-shadow: 0 0 0 4px rgba(131, 80, 230, 0.25); }
    .field-control-without-label:focus, .field-control-without-label:not(:placeholder-shown), .field-control-readonly:focus, .field-control-readonly:not(:placeholder-shown), .field-control:focus, .field-control:not(:placeholder-shown) {
      padding-top: 22px;
      padding-bottom: 7px; }
    .field-control-without-label:-webkit-autofill, .field-control-readonly:-webkit-autofill, .field-control:-webkit-autofill {
      padding-top: 22px;
      padding-bottom: 7px; }
    .field-control-without-label.disabled, .field-control-readonly.disabled, .field-control.disabled {
      opacity: 0.5 !important;
      background: var(--white) !important;
      border: 1px solid var(--gray-20);
      box-shadow: none;
      cursor: default;
      pointer-events: none !important; }
      .field-control-without-label.disabled ~ .field-icon-left,
      .field-control-without-label.disabled ~ .field-icon-right, .field-control-readonly.disabled ~ .field-icon-left,
      .field-control-readonly.disabled ~ .field-icon-right, .field-control.disabled ~ .field-icon-left,
      .field-control.disabled ~ .field-icon-right {
        opacity: 0.5 !important;
        border: none !important; }
      .field-control-without-label.disabled ~ .field-icon-left,
      .field-control-without-label.disabled ~ .field-icon-right,
      .field-control-without-label.disabled ~ label, .field-control-readonly.disabled ~ .field-icon-left,
      .field-control-readonly.disabled ~ .field-icon-right,
      .field-control-readonly.disabled ~ label, .field-control.disabled ~ .field-icon-left,
      .field-control.disabled ~ .field-icon-right,
      .field-control.disabled ~ label {
        opacity: 0.3 !important;
        box-shadow: none;
        cursor: default;
        pointer-events: none !important; }
    .field-control-without-label.invalid, .field-control-readonly.invalid, .field-control.invalid {
      border-color: var(--red-80) !important; }
      .field-control-without-label.invalid ~ label, .field-control-readonly.invalid ~ label, .field-control.invalid ~ label {
        transform: translateY(-8px) !important;
        font-size: var(--font-size-label-filled-mb) !important; }
  .field-control-without-label {
    padding: 8px 12px !important; }
  .field-error {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: var(--red);
    margin-top: 4px;
    margin-left: 12px; }
  .field > .filled ~ label,
  .field > .focus ~ label,
  .field > .field-control:focus ~ label,
  .field > .field-control-readonly ~ label {
    transform: translateY(-8px) !important;
    font-size: var(--font-size-label-filled-mb) !important; }
  .field > .field-control-readonly {
    background: var(--gray-5);
    pointer-events: none; }
    .field > .field-control-readonly ~ label {
      border-width: 1px 0; }
  .field label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 16px 12px;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    font-size: var(--font-size-label-only-mb);
    transition: all 0.1s;
    color: var(--gray-50);
    line-height: inherit; }

@media (min-width: 840px) {
  .field-control {
    font-size: var(--font-size-default-dp) !important;
    min-height: 56px !important; }
    .field-control.invalid ~ label {
      font-size: var(--font-size-label-filled-dp) !important; }
  .field-error {
    font-size: 14px !important; }
  .field > .filled ~ label,
  .field > .focus ~ label,
  .field > .field-control:focus ~ label,
  .field > .field-control-readonly ~ label {
    font-size: var(--font-size-label-filled-dp) !important; }
  .field.icon-left .field-control {
    padding-left: 10px !important; }
  .field.icon-left > label {
    padding-left: 10px !important; } }
`;
export const LAYOUT = `.max-width {
  max-width: 1040px;
  margin: auto; }

.max-width-small {
  max-width: 480px;
  margin: auto; }

.page-container {
  min-height: 100vh;
  overflow-x: hidden;
  display: flex;
  margin-top: env(safe-area-inset-top);
  margin-bottom: env(safe-area-inset-bottom);
  background: var(--gray-5); }

.page-container-content {
  align-self: center;
  max-width: 516px;
  width: 100%;
  margin: 0 auto; }

.page-segment {
  padding: 16px;
  background: var(--white);
  border-bottom: 1px solid var(--gray-10);
  border-radius: 16px; }

@media (max-width: 516px) {
  .page-segment {
    border-radius: 0px; } }

.session-card {
  display: grid;
  grid-template-columns: 16px auto;
  overflow: hidden;
  border-radius: 16px; }
  .session-card-border {
    background: var(--purple); }
  .session-card-container {
    display: grid;
    grid-template-columns: 56px auto;
    gap: 2px 8px;
    border-radius: 0 16px 16px 0;
    background: var(--gray-5);
    padding: 16px;
    align-items: center; }
    .session-card-container-image {
      grid-column: 1;
      grid-row: 1 / 4;
      width: 56px;
      height: 56px;
      border-radius: 50%;
      background: var(--gray-10);
      object-fit: cover; }
    .session-card-container-title {
      grid-column: 2;
      grid-row: 1; }
    .session-card-container-subtitle {
      grid-column: 2;
      grid-row: 2; }
    .session-card-container-footer {
      grid-column: 2;
      grid-row: 3; }

.gray-badge {
  display: flex;
  border-color: var(--gray-60);
  background-color: var(--gray-60);
  border-radius: 50%;
  width: 22px;
  height: 22px;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  font-weight: 400;
  line-height: 1;
  font-size: 14px; }
`;
export const LOADERS = `.loader-spinner {
  width: 24px;
  height: 24px;
  border: 4px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite; }

@keyframes rotation {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
`;
export const MAIN = `@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
* {
  font-family: "Open Sans", sans-serif;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: none;
  box-sizing: border-box; }

html,
body {
  margin: 0; }

/* resets */
button,
input,
textarea {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  border: none;
  outline: none;
  background: none;
  padding: 0;
  margin: 0; }

button {
  cursor: pointer; }

a {
  text-decoration: none;
  color: inherit; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0; }
`;
export const MARGINS = `.margin-xs {
  margin: var(--xs) !important; }

.margin-xs-y {
  margin-top: var(--xs) !important;
  margin-bottom: var(--xs) !important; }

.margin-xs-x {
  margin-left: var(--xs) !important;
  margin-right: var(--xs) !important; }

.margin-xs-left {
  margin-left: var(--xs) !important; }

.margin-xs-right {
  margin-right: var(--xs) !important; }

.margin-xs-top {
  margin-top: var(--xs) !important; }

.margin-xs-bottom {
  margin-bottom: var(--xs) !important; }

.margin-sm {
  margin: var(--sm) !important; }

.margin-sm-y {
  margin-top: var(--sm) !important;
  margin-bottom: var(--sm) !important; }

.margin-sm-x {
  margin-left: var(--sm) !important;
  margin-right: var(--sm) !important; }

.margin-sm-left {
  margin-left: var(--sm) !important; }

.margin-sm-right {
  margin-right: var(--sm) !important; }

.margin-sm-top {
  margin-top: var(--sm) !important; }

.margin-sm-bottom {
  margin-bottom: var(--sm) !important; }

.margin-md {
  margin: var(--md) !important; }

.margin-md-y {
  margin-top: var(--md) !important;
  margin-bottom: var(--md) !important; }

.margin-md-x {
  margin-left: var(--md) !important;
  margin-right: var(--md) !important; }

.margin-md-left {
  margin-left: var(--md) !important; }

.margin-md-right {
  margin-right: var(--md) !important; }

.margin-md-top {
  margin-top: var(--md) !important; }

.margin-md-bottom {
  margin-bottom: var(--md) !important; }

.margin-lg {
  margin: var(--lg) !important; }

.margin-lg-y {
  margin-top: var(--lg) !important;
  margin-bottom: var(--lg) !important; }

.margin-lg-x {
  margin-left: var(--lg) !important;
  margin-right: var(--lg) !important; }

.margin-lg-left {
  margin-left: var(--lg) !important; }

.margin-lg-right {
  margin-right: var(--lg) !important; }

.margin-lg-top {
  margin-top: var(--lg) !important; }

.margin-lg-bottom {
  margin-bottom: var(--lg) !important; }

.margin-xl {
  margin: var(--xl) !important; }

.margin-xl-y {
  margin-top: var(--xl) !important;
  margin-bottom: var(--xl) !important; }

.margin-xl-x {
  margin-left: var(--xl) !important;
  margin-right: var(--xl) !important; }

.margin-xl-left {
  margin-left: var(--xl) !important; }

.margin-xl-right {
  margin-right: var(--xl) !important; }

.margin-xl-top {
  margin-top: var(--xl) !important; }

.margin-xl-bottom {
  margin-bottom: var(--xl) !important; }
`;
export const PADDINGS = `.padding-xs {
  padding: var(--xs); }

.padding-xs-y {
  padding-top: var(--xs);
  padding-bottom: var(--xs); }

.padding-xs-x {
  padding-left: var(--xs);
  padding-right: var(--xs); }

.padding-xs-left {
  padding-left: var(--xs); }

.padding-xs-right {
  padding-right: var(--xs); }

.padding-xs-top {
  padding-top: var(--xs); }

.padding-xs-bottom {
  padding-bottom: var(--xs); }

.padding-sm {
  padding: var(--sm); }

.padding-sm-y {
  padding-top: var(--sm);
  padding-bottom: var(--sm); }

.padding-sm-x {
  padding-left: var(--sm);
  padding-right: var(--sm); }

.padding-sm-left {
  padding-left: var(--sm); }

.padding-sm-right {
  padding-right: var(--sm); }

.padding-sm-top {
  padding-top: var(--sm); }

.padding-sm-bottom {
  padding-bottom: var(--sm); }

.padding-md {
  padding: var(--md); }

.padding-md-y {
  padding-top: var(--md);
  padding-bottom: var(--md); }

.padding-md-x {
  padding-left: var(--md);
  padding-right: var(--md); }

.padding-md-left {
  padding-left: var(--md); }

.padding-md-right {
  padding-right: var(--md); }

.padding-md-top {
  padding-top: var(--md); }

.padding-md-bottom {
  padding-bottom: var(--md); }

.padding-lg {
  padding: var(--lg); }

.padding-lg-y {
  padding-top: var(--lg);
  padding-bottom: var(--lg); }

.padding-lg-x {
  padding-left: var(--lg);
  padding-right: var(--lg); }

.padding-lg-left {
  padding-left: var(--lg); }

.padding-lg-right {
  padding-right: var(--lg); }

.padding-lg-top {
  padding-top: var(--lg); }

.padding-lg-bottom {
  padding-bottom: var(--lg); }

.padding-xl {
  padding: var(--xl); }

.padding-xl-y {
  padding-top: var(--xl);
  padding-bottom: var(--xl); }

.padding-xl-x {
  padding-left: var(--xl);
  padding-right: var(--xl); }

.padding-xl-left {
  padding-left: var(--xl); }

.padding-xl-right {
  padding-right: var(--xl); }

.padding-xl-top {
  padding-top: var(--xl); }

.padding-xl-bottom {
  padding-bottom: var(--xl); }
`;
export const TYPOGRAPHY = `.bold {
  font-weight: 600 !important; }

.bold-700 {
  font-weight: 700 !important; }

.bold-800 {
  font-weight: 800 !important; }

.heading-tag {
  color: var(--gray-80);
  font-size: 16px;
  font-weight: 700;
  line-height: 120%; }

.heading-medium {
  color: var(--gray-80);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; }

.heading {
  color: var(--gray-80);
  font-weight: 700;
  font-size: 26px;
  line-height: 120%; }

.heading-big {
  color: var(--gray-80);
  font-weight: 700;
  font-size: 36px;
  line-height: 120%; }

.heading-small {
  color: var(--gray-80);
  font-weight: 700;
  font-size: 18px; }

.heading-display {
  font-weight: 400;
  font-size: 28px;
  line-height: 140%;
  color: var(--gray-80); }

.body {
  color: var(--gray-60);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%; }

.body-small {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: var(--gray-60); }

.body-big {
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: var(--gray-60); }

.content-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: var(--gray-80); }

.content-small {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: var(--gray-60); }

.datazero-text {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: var(--gray-80); }

.small-tag {
  font-size: 12px;
  line-height: 13px;
  font-weight: 600;
  color: var(--gray-80); }

.small {
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  color: var(--gray-60); }

.text-center {
  text-align: center; }

.link {
  cursor: pointer; }

@media screen and (max-width: 840px) {
  .body {
    font-size: 14px; }
  .body-small {
    font-size: 12px; }
  .body-big {
    font-size: 16px; }
  .datazero-text {
    font-size: 14px;
    line-height: 20px; }
  .heading-tag {
    font-size: 14px; }
  .heading {
    font-size: 20px; }
  .heading-small {
    font-size: 16px; }
  .heading-display {
    font-size: 24px; }
  .content-title {
    font-size: 14px;
    line-height: 20px; }
  .content-small {
    line-height: 17px;
    font-size: 12px; }
  .small-tag {
    font-size: 10px;
    line-height: 14px;
    color: var(--gray-50); }
  .small {
    font-size: 10px; } }
`;
export const VARIABLES = `:root {
  /* Dark purple */
  --dark-purple: #4C14A5;
  /* Purple */
  --purple: #8350E6;
  --purple-5: #F9F6FE;
  --purple-10: #F3EEFC;
  --purple-20: #E6DCFA;
  --purple-40: #CDB9F5;
  --purple-50: #C1A7F2;
  --purple-60: #B596F0;
  --purple-80: #9C73EB;
  --purple-110: #693FCB;
  --shadow-purple: 0px 4px 16px rgba(131, 80, 230, 0.2);
  /* White */
  --white: #FFFFFF;
  --white-5: #F8F9FB;
  --white-20: #EFF1F5;
  --white-60: #DDE1E9;
  --white-80: #ffffffcc;
  /* Gray */
  --gray-5: #F8F9FB;
  --gray-10: #EFF1F5;
  --gray-20: #DDE1E9;
  --gray-40: #C3C8D5;
  --gray-50: #8D91A0;
  --gray-60: #5A5E67;
  --gray-80: #3E4047;
  --gray-100: #000;
  /* Black */
  --black-full: #000000;
  --black: #100F0F;
  --black-10: #E8E8E8;
  --black-20: #D0D0D0;
  --black-40: #A1A1A2;
  --black-50: #8A8A8A;
  --black-60: #737373;
  --black-80: #2b2a33;
  /* Green */
  --green-130: #144835;
  --green-120: #1D674C;
  --green-110: #258361;
  --green-dark: #144835;
  --green: #2FA77B;
  --green-20: #DFF6EE;
  --green-40: #BFEDDC;
  --green-50: #A0E4CB;
  --green-60: #80DBBA;
  --green-80: #40C998;
  /* Blue */
  --blue: #0E97F2;
  --blue-5: #F4FBFF;
  --blue-10: #E5F3FC;
  --blue-20: #CFEAFC;
  --blue-40: #9FD5FA;
  --blue-50: #86CBF8;
  --blue-60: #6EC1F7;
  --blue-80: #3EACF5;
  --blue-120: #0E5D95;
  --blue-dark: #0A4670;
  /* Yellow */
  --yellow: #E29D18;
  --yellow-5: #FFFBF0;
  --yellow-10: #FFF6E0;
  --yellow-20: #FFF0C7;
  --yellow-40: #FFE6A6;
  --yellow-50: #FFDD87;
  --yellow-60: #FFD364;
  --yellow-80: #F8BA37;
  --yellow-110: #C0820A;
  --yellow-120: #946100;
  --yellow-130: #613F00;
  /* Red */
  --red-dark: #7A1616;
  --red: #E72524;
  --red-80: #FE6E6D;
  --red-60: #FE9292;
  --red-50: #FEA4A4;
  --red-40: #FFB7B6;
  --red-20: #FFDBDB;
  --red-10: #FFEDED;
  --red-5: #FFF5F5;
  --orange-130: #8F3300;
  --orange-120: #C24600;
  --orange-110: #FF620A;
  --orange: #FF833E;
  --orange-80: #FF9D66;
  --orange-60: #FFB78F;
  --orange-50: #FFC4A3;
  --orange-40: #FFD1B8;
  --orange-20: #FFE5D6;
  --orange-10: #FFEBE0;
  --orange-5: #FFF5F0;
  /* Sizes */
  --xs: 10px;
  --sm: 12px;
  --md: 16px;
  --lg: 18px;
  --xl: 24px;
  --font: 'Open Sans';
  --font-size-default-mb: 14px;
  --font-size-label-only-mb: 14px;
  --font-size-label-filled-mb: 10px;
  --font-size-default-dp: 16px;
  --font-size-label-only-dp: 16px;
  --font-size-label-filled-dp: 12px; }
`;
export const ICONS = `@font-face {
  font-family: 'selia-icons';
  src: url("fonts/selia-icons.eot?ls6ejm");
  src: url("fonts/selia-icons.eot?ls6ejm#iefix") format("embedded-opentype"), url("fonts/selia-icons.ttf?ls6ejm") format("truetype"), url("fonts/selia-icons.woff?ls6ejm") format("woff"), url("fonts/selia-icons.svg?ls6ejm#selia-icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'selia-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-filter-arrow-down .path1 :before {
  content: "\e9e8";
  color: #3e4047; }

.icon-filter-arrow-down .path2 :before {
  content: "\e9e9";
  margin-left: -1em;
  color: #8d91a0; }

.icon-filter-arrow-up .path1 :before {
  content: "\e9ea";
  color: #8d91a0; }

.icon-filter-arrow-up .path2 :before {
  content: "\e9eb";
  margin-left: -1em;
  color: #3e4047; }

.icon-filter-arrow :before {
  content: "\e9ec"; }

.icon-bolt-on :before {
  content: "\e9e6"; }

.icon-bolt-off :before {
  content: "\e9e7"; }

.icon-recommended-off:before {
  content: "\e9e4"; }

.icon-meditation-person:before {
  content: "\e9e3"; }

.icon-connection-warn:before {
  content: "\e9e1"; }

.icon-wallet:before {
  content: "\e96e"; }

.icon-certificate:before {
  content: "\e9e0"; }

.icon-about-me:before {
  content: "\e900"; }

.icon-specialities:before {
  content: "\e901"; }

.icon-therapeutic-approach:before {
  content: "\e902"; }

.icon-consultory-directory:before {
  content: "\e903"; }

.icon-prices-offered:before {
  content: "\e904"; }

.icon-group:before {
  content: "\e9da"; }

.icon-nutrition-off:before {
  content: "\e9db"; }

.icon-nutrition-on:before {
  content: "\e9dc"; }

.icon-arrow-curve-left:before {
  content: "\e9d3"; }

.icon-arrow-curve-right:before {
  content: "\e9d6"; }

.icon-my-package-off:before {
  content: "\e9d8"; }

.icon-my-package-on:before {
  content: "\e9d9"; }

.icon-heart-minus:before {
  content: "\e9d4"; }

.icon-heart-plus:before {
  content: "\e9d5"; }

.icon-give:before {
  content: "\e9d7"; }

.icon-warning-triangle-on:before {
  content: "\e9d2"; }

.icon-activity:before {
  content: "\e92a"; }

.icon-audio:before {
  content: "\e92c"; }

.icon-click-hand-off:before {
  content: "\e935"; }

.icon-click-hand-on:before {
  content: "\e9d0"; }

.icon-meditation:before {
  content: "\e9d1"; }

.icon-alert-circle-outline:before {
  content: "\e905"; }

.icon-alphabetic:before {
  content: "\e906"; }

.icon-android:before {
  content: "\e907"; }

.icon-apple:before {
  content: "\e908"; }

.icon-appointment-off:before {
  content: "\e909"; }

.icon-appointment-on:before {
  content: "\e90a"; }

.icon-archive-off:before {
  content: "\e90b"; }

.icon-archive-on:before {
  content: "\e90c"; }

.icon-arrow-big-outline-down:before {
  content: "\e90d"; }

.icon-arrow-big-outline-left:before {
  content: "\e90e"; }

.icon-arrow-big-outline-right:before {
  content: "\e90f"; }

.icon-arrow-big-outline-up:before {
  content: "\e910"; }

.icon-arrow-full-outline-down:before {
  content: "\e911"; }

.icon-arrow-full-outline-left:before {
  content: "\e912"; }

.icon-arrow-full-outline-right:before {
  content: "\e913"; }

.icon-arrow-full-outline-up:before {
  content: "\e914"; }

.icon-arrow-small-fill-down:before {
  content: "\e915"; }

.icon-arrow-small-fill-left:before {
  content: "\e916"; }

.icon-arrow-small-fill-right:before {
  content: "\e917"; }

.icon-arrow-small-fill-up:before {
  content: "\e918"; }

.icon-arrow-small-outline-down:before {
  content: "\e919"; }

.icon-arrow-small-outline-left:before {
  content: "\e91a"; }

.icon-arrow-small-outline-right:before {
  content: "\e91b"; }

.icon-arrow-small-outline-up-down:before {
  content: "\e91c"; }

.icon-arrow-small-outline-up:before {
  content: "\e91d"; }

.icon-award-off:before {
  content: "\e91e"; }

.icon-award-on:before {
  content: "\e91f"; }

.icon-bank:before {
  content: "\e920"; }

.icon-book-closed-filled:before {
  content: "\e921"; }

.icon-book-closed:before {
  content: "\e922"; }

.icon-book-open-filled:before {
  content: "\e923"; }

.icon-book-open:before {
  content: "\e924"; }

.icon-briefcase-off:before {
  content: "\e925"; }

.icon-briefcase-on:before {
  content: "\e926"; }

.icon-burger-menu:before {
  content: "\e927"; }

.icon-bx-send:before {
  content: "\e928"; }

.icon-bx-task:before {
  content: "\e929"; }

.icon-calendar-day:before {
  content: "\e92b"; }

.icon-calendar-month:before {
  content: "\e92d"; }

.icon-calendar-unavailable-filled:before {
  content: "\e92e"; }

.icon-calendar-unavailable:before {
  content: "\e92f"; }

.icon-calendar-week:before {
  content: "\e930"; }

.icon-check-filled:before {
  content: "\e931"; }

.icon-check:before {
  content: "\e932"; }

.icon-checkbox-off:before {
  content: "\e933"; }

.icon-checkbox-on:before {
  content: "\e934"; }

.icon-checkbox-outlined-on:before {
  content: "\e936"; }

.icon-checkmark-circle-off:before {
  content: "\e937"; }

.icon-checkmark-circle-on:before {
  content: "\e938"; }

.icon-checkmark-semi-circle-filled:before {
  content: "\e939"; }

.icon-checkmark-semi-circle:before {
  content: "\e93a"; }

.icon-clipboard-off:before {
  content: "\e93b"; }

.icon-clipboard-on:before {
  content: "\e93c"; }

.icon-clock-off:before {
  content: "\e93d"; }

.icon-clock-on:before {
  content: "\e93e"; }

.icon-close-circle-off:before {
  content: "\e93f"; }

.icon-close-circle-on:before {
  content: "\e940"; }

.icon-close:before {
  content: "\e941"; }

.icon-code:before {
  content: "\e942"; }

.icon-conversation-bubbles-on:before {
  content: "\e943"; }

.icon-copy-off:before {
  content: "\e944"; }

.icon-copy-on:before {
  content: "\e945"; }

.icon-creditcard-off:before {
  content: "\e946"; }

.icon-creditcard-on:before {
  content: "\e947"; }

.icon-discount-off:before {
  content: "\e948"; }

.icon-discount-on:before {
  content: "\e949"; }

.icon-discount:before {
  content: "\e94a"; }

.icon-dots-off:before {
  content: "\e94b"; }

.icon-dots-on:before {
  content: "\e94c"; }

.icon-download:before {
  content: "\e94d"; }

.icon-drawer:before {
  content: "\e94e"; }

.icon-edit-off:before {
  content: "\e94f"; }

.icon-edit-on:before {
  content: "\e950"; }

.icon-expand:before {
  content: "\e951"; }

.icon-eyes-closed:before {
  content: "\e952"; }

.icon-eyes-off-fill:before {
  content: "\e953"; }

.icon-Eyes-off-outline:before {
  content: "\e954"; }

.icon-eyes-on-outline:before {
  content: "\e955"; }

.icon-eyeso-n-fill:before {
  content: "\e956"; }

.icon-facebook:before {
  content: "\e957"; }

.icon-filter-default:before {
  content: "\e958"; }

.icon-filter-off:before {
  content: "\e959"; }

.icon-folder-off:before {
  content: "\e95a"; }

.icon-folder-on:before {
  content: "\e95b"; }

.icon-globe-off:before {
  content: "\e95c"; }

.icon-globe-on:before {
  content: "\e95d"; }

.icon-google:before {
  content: "\e95e"; }

.icon-graph-off:before {
  content: "\e95f"; }

.icon-graph-on:before {
  content: "\e960"; }

.icon-headphones-off:before {
  content: "\e961"; }

.icon-headphones-on:before {
  content: "\e962"; }

.icon-heart-off:before {
  content: "\e963"; }

.icon-heart-on:before {
  content: "\e964"; }

.icon-home-off:before {
  content: "\e965"; }

.icon-home-on:before {
  content: "\e966"; }

.icon-information-off:before {
  content: "\e967"; }

.icon-information-on:before {
  content: "\e968"; }

.icon-instagram:before {
  content: "\e969"; }

.icon-language:before {
  content: "\e96a"; }

.icon-linkedIn:before {
  content: "\e96b"; }

.icon-lock-off:before {
  content: "\e96c"; }

.icon-lock-on:before {
  content: "\e96d"; }

.icon-login:before {
  content: "\e9e2"; }

.icon-logout:before {
  content: "\e96f"; }

.icon-mail-off:before {
  content: "\e970"; }

.icon-mail-on:before {
  content: "\e971"; }

.icon-message-circle-off:before {
  content: "\e972"; }

.icon-message-circle-on:before {
  content: "\e973"; }

.icon-message-off:before {
  content: "\e974"; }

.icon-message-on:before {
  content: "\e975"; }

.icon-mic-off-off:before {
  content: "\e976"; }

.icon-mic-off-on:before {
  content: "\e977"; }

.icon-mic-off:before {
  content: "\e978"; }

.icon-mic-on:before {
  content: "\e979"; }

.icon-minus:before {
  content: "\e97a"; }

.icon-money-off:before {
  content: "\e97b"; }

.icon-money-on:before {
  content: "\e97c"; }

.icon-mortarboard:before {
  content: "\e97d"; }

.icon-my-office-filled:before {
  content: "\e97e"; }

.icon-my-office-off:before {
  content: "\e97f"; }

.icon-my-office-on:before {
  content: "\e980"; }

.icon-my-office:before {
  content: "\e981"; }

.icon-notes-off:before {
  content: "\e982"; }

.icon-notes-on:before {
  content: "\e983"; }

.icon-notifications-off:before {
  content: "\e984"; }

.icon-notifications-on:before {
  content: "\e985"; }

.icon-para-ti-off:before {
  content: "\e986"; }

.icon-para-ti-on:before {
  content: "\e987"; }

.icon-pause-circle-off:before {
  content: "\e988"; }

.icon-pause-circle-on:before {
  content: "\e989"; }

.icon-pause:before {
  content: "\e98a"; }

.icon-person-add-off:before {
  content: "\e98b"; }

.icon-person-add-on:before {
  content: "\e98c"; }

.icon-person-off:before {
  content: "\e98d"; }

.icon-person-on:before {
  content: "\e98e"; }

.icon-phone-missed-off:before {
  content: "\e98f"; }

.icon-phone-missed-on:before {
  content: "\e990"; }

.icon-phone-off:before {
  content: "\e991"; }

.icon-phone-on:before {
  content: "\e992"; }

.icon-pin-off:before {
  content: "\e993"; }

.icon-pin-on:before {
  content: "\e994"; }

.icon-play-circle-off:before {
  content: "\e995"; }

.icon-play-circle-on:before {
  content: "\e996"; }

.icon-play:before {
  content: "\e997"; }

.icon-plus-circle-off:before {
  content: "\e998"; }

.icon-plus-circle-on:before {
  content: "\e999"; }

.icon-plus:before {
  content: "\e99a"; }

.icon-pricetag-off:before {
  content: "\e99b"; }

.icon-pricetag-on:before {
  content: "\e99c"; }

.icon-question-mark-off:before {
  content: "\e99d"; }

.icon-question-mark-on:before {
  content: "\e99e"; }

.icon-radio-off:before {
  content: "\e99f"; }

.icon-radio-on:before {
  content: "\e9a0"; }

.icon-recommend-off:before {
  content: "\e9a1"; }

.icon-recommend-on:before {
  content: "\e9a2"; }

.icon-refresh:before {
  content: "\e9a3"; }

.icon-review-off:before {
  content: "\e9a4"; }

.icon-review-on:before {
  content: "\e9a5"; }

.icon-rewind-left:before {
  content: "\e9a6"; }

.icon-rewind-right:before {
  content: "\e9a7"; }

.icon-search-off:before {
  content: "\e9a8"; }

.icon-search-on:before {
  content: "\e9a9"; }

.icon-settings-off:before {
  content: "\e9aa"; }

.icon-settings-on:before {
  content: "\e9ab"; }

.icon-share-off:before {
  content: "\e9ac"; }

.icon-share-on:before {
  content: "\e9ad"; }

.icon-share-screen-outline:before {
  content: "\e9ae"; }

.icon-specialists-off:before {
  content: "\e9af"; }

.icon-specialists-on:before {
  content: "\e9b0"; }

.icon-star-off:before {
  content: "\e9b1"; }

.icon-star-on:before {
  content: "\e9b2"; }

.icon-support-chat-off:before {
  content: "\e9b3"; }

.icon-support-chat-on:before {
  content: "\e9b4"; }

.icon-support-off:before {
  content: "\e9b5"; }

.icon-support-on:before {
  content: "\e9b6"; }

.icon-tag-filled:before {
  content: "\e9b7"; }

.icon-tag:before {
  content: "\e9b8"; }

.icon-text-editors-bold:before {
  content: "\e9b9"; }

.icon-text-editors-file:before {
  content: "\e9ba"; }

.icon-text-editors-hash:before {
  content: "\e9bb"; }

.icon-text-editors-italic:before {
  content: "\e9bc"; }

.icon-text-editors-link:before {
  content: "\e9bd"; }

.icon-text-editors-list:before {
  content: "\e9be"; }

.icon-text-editors-text:before {
  content: "\e9bf"; }

.icon-timer-calendar-off:before {
  content: "\e9c0"; }

.icon-timer-calendar-on:before {
  content: "\e9c1"; }

.icon-timer-grid-off:before {
  content: "\e9c2"; }

.icon-timer-grid-on:before {
  content: "\e9c3"; }

.icon-timer-off:before {
  content: "\e9c4"; }

.icon-timer-on:before {
  content: "\e9c5"; }

.icon-trash-off:before {
  content: "\e9c6"; }

.icon-trash-on:before {
  content: "\e9c7"; }

.icon-twitter:before {
  content: "\e9c8"; }

.icon-upload:before {
  content: "\e9c9"; }

.icon-video-off-off:before {
  content: "\e9ca"; }

.icon-video-off-on:before {
  content: "\e9cb"; }

.icon-video-outline-off:before {
  content: "\e9cc"; }

.icon-video-outline-on:before {
  content: "\e9cd"; }

.icon-warning-circle-off:before {
  content: "\e9ce"; }

.icon-warning-circle-on:before {
  content: "\e9cf"; }

.icon-whatsapp:before {
  content: "\e9dd"; }

.icon-white-board-on:before {
  content: "\e9de"; }

.icon-youtube:before {
  content: "\e9df"; }
`;
