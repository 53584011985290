<template>
  <div v-if="userId && appointmentId" style="height: 100vh; width: 100vw;">
    <selia-video v-if="settings.videoCallVersion === 2"
      :appointment-id="appointmentId"
      :user-id="userId"
      :lang="lang"
      :app-version="$version"
      origin="patient"
      @onLeave="onLeave"
      @leaved="onCompleted"
      @joined="callJoined"
      @back="$router.push('/')"
      @remote-ready="handleRemoteReady"
      @show-chat="showChat = true"
      ref="seliaVideo"
    >
      <chat
        v-if="members && remote && showChat"
        :allow-navigation="false"
        :show-main-loader="false"
        :members="members"
        hide-header="true"
      />
    </selia-video>

    <template v-else-if="settings.videoCallVersion === 3 && videoCallConfig.sessionId">
      <video-call
        :language="videoCallConfig.language"
        :sessionId="videoCallConfig.sessionId"
        :environment="videoCallConfig.environment"
        :origin="'patient'"
        :authorization="videoCallConfig.authorization"
        @leave-session="onCompleted"
        @leave-lobby="$router.push('/')"
      />
    </template>
  </div>
</template>

<script>
import {Appointments, SeliaVideo} from '@seliaco/red-panda'
import '@seliaco/giant-panda-video-call'
import { SessionEvent } from '@/types/events'
import { mapGetters } from 'vuex'
import Chat from '@/views/chat/Chat.vue'

export default {
  name: 'Session',
  components: {
    Chat,
    SeliaVideo
  },
  data () {
    return {
      videoSrc: null,
      appointmentId: null,
      appointmentType: null,
      userId: null,
      inCallCounter: -1,
      inCallMinutesMax: 5,
      origin: 'user',
      remote: null,
      showChat: false,
      members: null,
      serviceName: null,
      videoCallConfig: {
        language: null,
        sessionId: null,
        environment: null,
        authorization: null,
      }
    }
  },
  mounted () {
    if (this.settings.videoCallVersion) {
      this.init()
    } else {
      this.$store.watch(
        () => this.settings.videoCallVersion,
        () => {
          this.init()
        }
      )
    }
  },
  beforeCreate () {
    this.$router.push({ query: { ...this.$route.query, disableBack: 'true' } })
  },
  destroyed () {
    const link = document.getElementById('giant-panda-styles')
    link.parentNode.removeChild(link)

    this.$router.push({ query: { ...this.$route.query, disableBack: null } })
  },
  methods: {
    init () {
      this.appointmentId = this.$route.params.id
      this.userId = this.$store.getters['auth/user'].id

      if (this.settings.videoCallVersion === 3) {
        this.initVersion3()
      }
      this.getAppointmentData()
    },
    async initVersion3 () {
      const link = document.createElement('link')
      link.id = 'giant-panda-styles'
      link.rel = 'stylesheet'
      link.href = 'https://unpkg.com/@seliaco/giant-panda-styles/style.css'
      document.head.appendChild(link)

      const isProd = process.env.VUE_APP_MODE === 'PROD'
      const response = await fetch(`https://video${isProd ? '' : '-dev'}.selia.co/api/v1/user/id-sign-in`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id: this.userId }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      this.videoCallConfig = {
        language: this.lang,
        sessionId: this.appointmentId,
        environment: isProd ? 'production' : 'staging',
        authorization: data.authorization,
      }
    },
    callJoined (serviceName) {
      SessionEvent.openCall({
        service: serviceName,
        user: this.$store.getters['auth/user']
      })
    },
    onJoined () {
      this.inCallCounter += 1

      setTimeout(() => {
        if (this.inCallCounter !== this.inCallMinutesMax) {
          this.onJoined()
        }
      }, 60000)
    },
    onLeave (serviceName) {
      SessionEvent.leaveCall({
        service: serviceName,
        user: this.$store.getters['auth/user']
      })
    },
    async onCompleted (e) {
      if (e?.detail?.reason === 'ERROR') {
        return this.$router.push('/')
      }

      SessionEvent.leaveCall({
        service: this.serviceName,
        user: this.$store.getters['auth/user']
      })
      await this.$router.push({
        name: 'Aftercall',
        params: { id: this.appointmentId }
      })
    },
    handleRemoteReady (remote) {
      this.remote = remote
      this.members = [this.remote.id, this.$store.getters['auth/user'].id]
    },
    async getAppointmentData () {
      const appointment = await Appointments.getUsrAppointmentDetail(
        this.appointmentId
      )

      this.serviceName = appointment?.appointment?.service?.service_type?.name

      if (appointment?.created_at) {
        let specialistId = appointment?.specialist?.user?.id

        this.members = [specialistId, this.$store.getters['auth/user'].id]
      }
    }
  },
  computed: {
    ...mapGetters({
      settings: 'settings/getSettings'
    }),
    lang () {
      return String(this.$store.getters.language.lang)
    },
  },
  watch: {
    $route (to) {
      if (!to.query.disableBack) {
        this.$router.push({ query: { ...to.query, disableBack: 'true' } })
      }
    }
  }
}
</script>

<style scoped>
  iframe {
    border: none;
    outline: none;
    height: 100vh;
    width: 100vw;
  }
</style>
