<template>
  <selia-chat
    v-if="loaded"
    :language="language"
    :userId="userId"
    :environment="environment"
    :containerHeight="containerHeight"
    :channelName="channelName"
    :hideHeader="hideHeader"
    @header-click="handleHeaderClick"
  />
</template>

<script>
import '@seliaco/giant-panda-chat'

export default {
  name: 'Chat',
  props: {
    members: {
      type: Array,
      default: () => []
    },
    allowNavigation: {
      type: Boolean,
      default: true
    },
    hideHeader: {
      type: String,
      default: 'false'
    }
  },
  data () {
    return {
      loaded: false,
      language: null,
      userId: null,
      environment: null,
      containerHeight: null,
    }
  },
  mounted () {
    if (this.$store.getters['auth/user']) {
      this.init()
    } else {
      this.$store.watch(
        () => this.$store.getters['auth/user'],
        () => {
          this.init()
        }
      )
    }

    const link = document.createElement('link')
    link.id = 'giant-panda-styles'
    link.rel = 'stylesheet'
    link.href = 'https://unpkg.com/@seliaco/giant-panda-styles/style.css'
    document.head.appendChild(link)

    this.enableListeners()
  },
  destroyed () {
    const link = document.getElementById('giant-panda-styles')

    if (!link) {
      return
    }

    link.parentNode.removeChild(link)
    this.disableListeners()
  },
  methods: {
    init () {
      this.language = this.$store.getters.language.lang
      this.userId = this.$store.getters['auth/user'].id
      this.environment = process.env.VUE_APP_MODE === 'PROD' ? 'PRO' : 'DEV'
      this.loaded = true
    },
    enableListeners () {
      this.setContainerHeight()

      window.addEventListener('resize', this.setContainerHeight)

      const container = document.getElementById('mainContainerContent').querySelector('.max-width')

      if (!container) {
        return
      }

      const content = container.querySelector('.max-width')

      if (!content) {
        return
      }

      content.style.maxWidth = '100%'
    },
    disableListeners () {
      window.removeEventListener('resize', this.setContainerHeight)

      const container = document.getElementById('mainContainerContent')

      if (!container) {
        return
      }

      const content = container.querySelector('.max-width')

      if (!content) {
        return
      }

      content.style.maxWidth = ''
    },
    setContainerHeight () {
      const header = document.getElementById('mainContainerHeader')
      const footer = document.getElementById('mainContainerFooter')

      const containerHeightReduction = [header.offsetHeight]

      if (footer.offsetHeight < 100) {
        containerHeightReduction.push(footer.offsetHeight)
      }

      this.containerHeight = `${window.innerHeight - containerHeightReduction.reduce((a, b) => a + b, 0)}px`
    },
    handleHeaderClick (event) {
      if (!this.allowNavigation) {
        return
      }

      const user = event.detail.user
      const channel = event.detail.channel

      this.$router.replace({
        name: 'SpecialistDetail',
        params: {
          id: user.specialist.id
        },
        query: {
          back: this.$route.path,
          channelId: channel._id
        }
      })
    }
  },
  computed: {
    channelName () {
      if (!this.members?.length) {
        return ''
      }
      return [...this.members].sort().join(':')
    }
  }
}
</script>
